import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

function UserMainLogin({ onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e) => {
    e.preventDefault();

    setError("");
    setMessage(""); // Reset message state

    let bodyContent = JSON.stringify({ email, password });

    try {
      // Server endpoint
      let response = await fetch("/api/users/login", {
        method: "POST",
        body: bodyContent,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });

      const data = await response.json(); // Parse JSON response

      if (response.ok) {
        if (data.status === 'pendingApproval') {
          // Handle the pending approval status
          setMessage(data.message); // Show pending approval message
        } else {
          // Assuming onLoginSuccess updates isLoggedIn and userRole in App's state
          onLoginSuccess(data.role); // Ensure this line is correctly placed before navigate

          // Navigate to the redirect route provided by the server
          navigate(data.redirect);
        }
      } else {
        setError(data.message || "Incorrect details, please try again.");
      }
    } catch (networkError) {
      setError("Failed to connect to the server.");
    }
  };

  //User input fields
  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title mb-4 text-center">Login</h2>
              {error && <p className="text-danger">{error}</p>}
              {message && <p className="text-warning">{message}</p>}
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="login-email" className="form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="login-email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="login-password" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    id="login-password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserMainLogin;

import React, { useState } from 'react';

function GuardianCreateSkater() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [contactNumberParent, setContactNumberParent] = useState('');
  const [coachName, setCoachName] = useState('');
  const [wpnumber, setWpNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [successMessage, setSuccessMessage] = useState("");


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    const formData = {
      name,
      surname,
      contactNumberParent,
      coachName,
      wpnumber,
      dateOfBirth,
      // No need to include guardianId here; the server extracts it from the JWT token
    };
  
    const headersList = {
      "Accept": "*/*",
      "Content-Type": "application/json",
    };
  
    let bodyContent = JSON.stringify(formData);
  
    try {
      let response = await fetch("/api/users/register-skater", {
        method: "POST",
        body: bodyContent,
        headers: headersList,
        credentials: 'include', // Important: Include credentials to ensure cookies are sent with the request
      });
  
      if (response.ok) {
        console.log("Skater profile created successfully");
        setSuccessMessage("Skater profile created successfully!"); // Update the success message
          // Clear the success message after 2 seconds
  setTimeout(() => {
    setSuccessMessage("");
  }, 2000);
        // Optionally reset form fields here
        setName('');
        setSurname('');
        setContactNumberParent('');
        setCoachName('');
        setWpNumber('');
        setDateOfBirth('');
        // Reset form fields or navigate to another page
        // Additional handling could go here, such as updating the UI or redirecting the user
      } else {
        const errorData = await response.json(); // Parse the JSON error response
        console.error("Failed to create skater profile:", errorData.message);
        // Update the UI with the error message here
      }
    } catch (error) {
      console.error('Network error:', error);
      // Optionally, handle error feedback to the user here
  setSuccessMessage(""); // Clear success message in case of error
      // Update the UI with the network error message here
    }
  };
  
    

    return (
      
        <div className="container mt-4 text-center">
           {successMessage && (
    <div className="alert alert-success" role="alert">
      {successMessage}
    </div>
  )}
          <div className="row">
            <div className="col-12">
              <h2 className="text-center mb-4 font-weight-bold">
                CREATE SKATER PROFILE
              </h2>
            </div>
    <div>
              <form onSubmit={handleSubmit} className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="name" className="form-label">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="surname" className="form-label">
                    Surname:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    placeholder="Surname"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="contact-parent" className="form-label">
                    Contact Parent:
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    value={contactNumberParent}
                    onChange={(e) => setContactNumberParent(e.target.value)}
                    placeholder="Parent Contact No."
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="coach-name" className="form-label">
                    Coach:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={coachName}
                    onChange={(e) => setCoachName(e.target.value)}
                    placeholder="Coach Name"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="wp-number" className="form-label">
                    WP-Number:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={wpnumber}
                    onChange={(e) => setWpNumber(e.target.value)}
                    placeholder="WP Number"
                  />
                </div>
    
                <div className="col-md-6">
                  <label htmlFor="dateOfBirth" className="form-label">
                    Date of Birth:
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="dateOfBirth"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                  />
                </div>
                <div className="row g-2 mt-3">
                  <div className="col-12 col-md-auto">
                    <button type="submit" className="btn btn-primary w-100">
                      Create Profile
                    </button>
                  </div>
                </div>
              </form>
          </div>
          <div className="d-flex justify-content-center">
            <img
              src="/western-province-figure-skating.png"
              alt="WP_Logo"
              className="d-block mx-auto"
            />
          </div>
        </div>
        </div>
      );

}

export default GuardianCreateSkater;
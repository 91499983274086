import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// import LogoutButton from "../components/LogoutButton";
import { Navbar, Nav, Container, NavItem, NavDropdown} from 'react-bootstrap';

function AdminDashboard({ onLogout }) {
  const [expanded, setExpanded] = useState(false);

  const closeMenu = () => setExpanded(false);

  return (
    <Navbar collapseOnSelect expanded={expanded} expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand as={NavLink} to="/admin/home" onClick={closeMenu}>Admin Dashboard</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <NavItem>
              <Nav.Link as={NavLink} to="/admin/skater-profile" onClick={closeMenu}>Skater Profile</Nav.Link>
            </NavItem>
          <NavItem>
              <Nav.Link as={NavLink} to="/admin/update-patchcard" onClick={closeMenu}>Patch Card</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/admin/patch-history" onClick={closeMenu}>Patch Archive</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/admin/update-skater" onClick={closeMenu}>Update Skater</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/admin/pending-approval" onClick={closeMenu}>Approvals</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/admin/contact" onClick={closeMenu}>Contact</Nav.Link>
            </NavItem>
          </Nav>
          <Nav>
            <NavDropdown title="Account" id="collasible-nav-dropdown" alignRight>
              <NavDropdown.Item as={NavLink} to="/admin/update-admin" onClick={closeMenu}>View Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={NavLink} to="/login" onClick={onLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
            {/* <LogoutButton onLogout={onLogout} /> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminDashboard;

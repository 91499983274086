import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const SkaterHomePage = () => {
  const [userName, setUserName] = useState("");

      // Initialize useNavigate
      const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async (userId) => {
      try {
        const response = await fetch(`/api/users/current-user-profile`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch user profile: ${response.status}`);
        }
        //assign fetched data to userData
        const userData = await response.json();

        setUserName(userData.name); // Assuming the response includes a 'name' field
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchUserProfile();
  }, []);

      // Function to handle card click
      const handleCardClick = () => {
        // Navigate to the skater profile URL
        navigate('/skater/profile'); // Update with your actual skater profile path
      };

  return (
    <div className="container mt-4 text-center">
      <h2 className="text-center mb-4">SKATER PATCH</h2>
      <div className="card mx-auto" style={{ maxWidth: "500px" }}>
        <div className="card-body" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
          <h5 className="card-title">WELCOME {userName.toUpperCase()}</h5>
          <p className="card-text">VIEW YOUR PROFILE</p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <img
          src="/western-province-figure-skating.png"
          alt="WP_Logo"
          className="d-block mx-auto"
        />
      </div>
    </div>
  );
};

export default SkaterHomePage;

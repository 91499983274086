import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Container, NavItem, NavDropdown } from 'react-bootstrap';

const GuardianDashboard = ({ onLogout }) => {
  const [expanded, setExpanded] = useState(false);

  const closeMenu = () => setExpanded(false);

  return (
    <Navbar collapseOnSelect expanded={expanded} expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand as={NavLink} to="/legal-guardian/home" onClick={closeMenu}>Guardian Dashboard</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <NavItem>
              <Nav.Link as={NavLink} to="/legal-guardian/home" onClick={closeMenu}>Home</Nav.Link>
            </NavItem> */}
            <NavItem>
              <Nav.Link as={NavLink} to="/legal-guardian/dependent-profile" onClick={closeMenu}>Skater Profile</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/guardian/patch-card" onClick={closeMenu}>Patch Card</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/guardian/patch-archive" onClick={closeMenu}>Patch History</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/legal-guardian/create-skater" onClick={closeMenu}>Create Skater</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/guardian/update-skater" onClick={closeMenu}>Update Skater</Nav.Link>
            </NavItem>
            {/* Add additional navigation items here as needed */}
          </Nav>
          <Nav>
            <NavDropdown title="Account" id="collapsible-nav-dropdown" alignRight>
              <NavDropdown.Item as={NavLink} to="/guardian/profile" onClick={closeMenu}>Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={NavLink} to="/login" onClick={onLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default GuardianDashboard;

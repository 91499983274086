import React, { useEffect, useState } from "react";
//   //Add back for delete option
// import AdminDeleteUserPage from "./AdminDeleteUserPage";
import GuardianGetMinorSkaters from "./GuardianGetMinorSkaters";
// css
import "../style.css";

function GuardianUpdateSkater() {
  //Schema fields state.
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [coachName, setCoachName] = useState("");
  const [wpnumber, setWpNumber] = useState("");
  const [contactNumberParent, setContactNumberParent] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  //Uses selected userId as para, to endpoint to get the correct user.
  const handleUserSelect = async (selectedUserId) => {
    setUserId(selectedUserId);
    if (selectedUserId) {
      try {
        const response = await fetch(
          `/api/users/get-user-profile/${selectedUserId}`,
          {
            method: "GET",
            credentials: "include", // Include cookies in the request
            headers: {
              "Content-Yype": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        //Updates as set by user otherwise stays empty.
        const userData = await response.json();
        setName(userData.name || "");
        setSurname(userData.surname || "");
        setCoachName(userData.coachName || "");
        setWpNumber(userData.wpnumber || "");
        setContactNumberParent(userData.contactNumberParent || "");
        setDateOfBirth(userData.dateOfBirth || "");
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setName("");
      setSurname("");
      setCoachName("");
      setWpNumber("");
      setContactNumberParent("");
      setDateOfBirth("");
    }
  };

  useEffect(() => {
    if (userId) {
      handleUserSelect(userId);
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyContent = JSON.stringify({
      name,
      surname,
      coachName,
      wpnumber,
      contactNumberParent,
      dateOfBirth,
    });

    try {
      const response = await fetch(`/api/users/update-user-profile/${userId}`, {
        method: "PUT",
        credentials: "include", // Include cookies in the request
        headers: { "Content-Type": "application/json" },
        body: bodyContent,
      });

      if (response.ok) {
        console.log("Profile updated successfully");
        setSuccessMessage("Profile Updated Successfully");

        // Hide the success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);

        // Refresh user data
        handleUserSelect(userId);
      } else {
        console.error("Failed to update profile");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage("");
    }
  };

  //   //Add back for delete option
  //   const handleSkaterDeletion = () => {
  //     setUserId('');
  // }

  // JSX
  return (
    <div className="container mt-4 text-center">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center mb-4 font-weight-bold">
            UPDATE SKATER PROFILE
          </h2>
          {/* Update the display based on the user data returned in the prop
          Passing prop to search database for user and then returning it here to update the userSelect async
          function which updates the correct userId and fields */}
          < GuardianGetMinorSkaters onUserSelect={handleUserSelect} selectedUserId={userId} />
          {successMessage && (
            <div className="alert alert-success mt-3">{successMessage}</div>
          )}
        </div>

        {userId && ( // Render the form only when a user is selected. This is vital so that the database is not updated with random data before a userId has been created at
          //Login. Solution courtesy of YouTube and OpenAi.
          <form onSubmit={handleSubmit} className="row g-3">
            <div className="col-md-6">
              <label htmlFor="name" className="form-label">
                Name:
              </label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="surname" className="form-label">
                Surname:
              </label>
              <input
                type="text"
                className="form-control"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                placeholder="Surname"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="contact-parent" className="form-label">
                Contact Parent:
              </label>
              <input
                type="tel"
                className="form-control"
                value={contactNumberParent}
                onChange={(e) => setContactNumberParent(e.target.value)}
                placeholder="Parent Contact No."
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="coach-name" className="form-label">
                Coach:
              </label>
              <input
                type="text"
                className="form-control"
                value={coachName}
                onChange={(e) => setCoachName(e.target.value)}
                placeholder="Coach Name"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="wp-number" className="form-label">
                WP-Number:
              </label>
              <input
                type="text"
                className="form-control"
                value={wpnumber}
                onChange={(e) => setWpNumber(e.target.value)}
                placeholder="WP Number"
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="dateOfBirth" className="form-label">
                Date of Birth:
              </label>
              <input
                type="date"
                className="form-control"
                id="dateOfBirth"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>
            <div className="row g-2 mt-3">
              <div className="col-12 col-md-auto">
                <button type="submit" className="btn btn-primary w-100">
                  Update Profile
                </button>
              </div>
              {/* //   //Add back for delete option */}

              {/* <div className="col-12 col-md-auto">
                <AdminDeleteUserPage userId={userId} onSkaterDeleted={handleSkaterDeletion} />
              </div> */}
            </div>
          </form>
        )}
      </div>
      <div className="d-flex justify-content-center">
        <img
          src="/western-province-figure-skating.png"
          alt="WP_Logo"
          className="d-block mx-auto"
        />
      </div>
    </div>
  );
}

export default GuardianUpdateSkater;

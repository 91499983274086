import React, { useState, useEffect } from 'react';

function GuardianGetMinorSkaters({ onUserSelect, selectedUserId }) {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      const fetchUsers = async () => {
        setIsLoading(true);
        try {
          const response = await fetch("/api/users/skater/minors", {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (!response.ok) {
            throw new Error("Something went wrong!");
          }
          const data = await response.json();
          setUsers(data);
        } catch (error) {
          console.error("Failed to fetch users:", error);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchUsers();
    }, []);
  
    return (
      <div>
        {isLoading ? (
          <div>Loading skaters...</div>
        ) : (
          <select
            className="form-select"
            value={selectedUserId}
            onChange={(e) => onUserSelect(e.target.value)}
          >
            <option value="">Select a Skater</option>
            {users.map((user) => (
              <option key={user.id} value={user._id}>
                {user.name} {user.surname}
              </option>
            ))}
          </select>
        )}
      </div>
    );
}

export default GuardianGetMinorSkaters;

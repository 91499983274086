import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import UserRegistration from "./RegisterLogin/userRegistration";
import AdminPendingApproval from "./pages/AdminPendingApproval";
import UserMainLogin from "./RegisterLogin/userMainLogin";
import AdminDashboard from "./pages/AdminDashboard";
import AdminHomePage from "./pages/AdminHomePage";
import AdminUpdateSkaterProfile from "./pages/AdminUpdateSkaterProfile";
import AdminDisplaySkaterProfile from "./pages/AdminDisplaySkaterProfile";
import AdminUpdateAdmin from "./pages/AdminUpdateAdmin";
import AdminContactPage from "./pages/AdminContactPage";
import AdminUpdatePatchCard from "./pages/AdminUpdatePatchCard";
import AdminDisplayPatchHistory from "./pages/AdminDisplayPatchHistory";
import SkaterDashboard from "./pages/SkaterDashboard";
import SkaterHomePage from "./pages/SkaterHomePage";
import SkaterProfileDisplayPage from "./pages/SkaterProfileDisplayPage";
import SkaterContactPage from "./pages/SkaterContactPage";
import SkaterProfileUpdate from "./pages/SkaterProfileUpdate"
import CoachDashboard from "./pages/CoachDashboard";
import GuardianDashboard from "./pages/GuardianDashboard";
import GuardianCreateSkater from "./pages/GuardianCreateSkater";
import GuardianDisplaySkaterProfile from "./pages/GuardianDisplaySkaterProfile";
import GuardianUpdateSkater from "./pages/GuardianUpdateSkater";
import GuardianUpdatePatchCard from "./pages/GuardianUpdatePatchCard";
import GuardianUpdateGuardian from "./pages/GuardianUpdateGuardian";
import GuardianHomePage from "./pages/GuardianHomePage";
import GuardianDisplayPatchHistory from "./pages/GuardianDisplayPatchHistory";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);

  // Initialize state from localStorage
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const storedUserRole = localStorage.getItem("userRole");
    if (storedIsLoggedIn && storedUserRole) {
      setIsLoggedIn(storedIsLoggedIn);
      setUserRole(storedUserRole);
    }
        //Check state session
        const checkSession = async () => {
          try {
            const response = await fetch('/api/session/check', {
              credentials: 'include', // Important for including cookies
            });
            if (!response.ok) throw new Error('Session expired');
            // Session is still valid
          } catch (error) {
            alert('Your session has expired. Please log in again.');
            // Here, redirect to login or show a modal depending on your routing setup
          }
        };
        // Call this function periodically
        const intervalId = setInterval(checkSession, 20 * 60 * 1000); // Example: check every 5 minutes
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
  }, []);

  // Update localStorage when state changes
  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
    localStorage.setItem("userRole", userRole);
  }, [isLoggedIn, userRole]);

  const handleLoginSuccess = (role, name) => {
    setIsLoggedIn(true);
    setUserRole(role);
    localStorage.setItem("userRole", role);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserRole(null);
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userRole");
  };

    // Define the getRedirectPath function here
    const getRedirectPath = (role) => {
      switch (role) {
        case 'admin':
          return "/admin/home";
        case 'skater':
          return "/skater/home";
        case 'coach':
          return "/coach/home";
        case 'legalGuardian': // Assuming you'll add this later
          return "/legal-guardian/home";
        default:
          return "/login"; // Fallback for unhandled roles
      }
    };

  return (
    <Router>
      <div>
        {isLoggedIn && userRole === "admin" && <AdminDashboard onLogout={handleLogout} />}
        {isLoggedIn && userRole === "skater" && <SkaterDashboard onLogout={handleLogout} />}
        {isLoggedIn && userRole === "coach" && <CoachDashboard onLogout={handleLogout} />}
        {isLoggedIn && userRole === "legalGuardian" && <GuardianDashboard onLogout={handleLogout} />}
        <Routes>
          <Route path="/login" element={!isLoggedIn ? 
            <>
              <UserRegistration />
              <UserMainLogin onLoginSuccess={handleLoginSuccess} />
            </> : 
            // <Navigate to={userRole === "admin" ? "/admin/home" : "/skater/home"} />
            <Navigate to={getRedirectPath(userRole)} />
          } />
          <Route path="/admin/home" element={<AdminHomePage />} />
          <Route path="/admin/update-patchcard" element={<AdminUpdatePatchCard />} />
          <Route path="/admin/skater-profile" element={<AdminDisplaySkaterProfile/>} />
          <Route path="/admin/update-skater" element={<AdminUpdateSkaterProfile />} />
          <Route path="/admin/update-admin" element = {<AdminUpdateAdmin />} />
          <Route path="/admin/pending-approval" element = {<AdminPendingApproval />} />
          <Route path="/admin/contact" element={<AdminContactPage />} />
          <Route path="/admin/patch-history" element={< AdminDisplayPatchHistory />} />
          <Route path="/skater/contact" element={<SkaterContactPage />} />    
          <Route path="/skater/profile" element={<SkaterProfileDisplayPage />} />
          <Route path="/skater/home" element={<SkaterHomePage />} />
          <Route path="/skater/update-profile" element={<SkaterProfileUpdate />} />
          {/* GuardianRoutes */}
          <Route path="/legal-guardian/create-skater" element={<GuardianCreateSkater />} />
          <Route path="/legal-guardian/dependent-profile" element={<GuardianDisplaySkaterProfile />} />
          <Route path="/guardian/update-skater" element={<GuardianUpdateSkater/>} />
          <Route path="/guardian/patch-card" element={<GuardianUpdatePatchCard/>} />
          <Route path="/guardian/profile" element={<GuardianUpdateGuardian/>} />
          <Route path="/legal-guardian/home" element={<GuardianHomePage/>} />
          <Route path="/guardian/patch-archive" element={<GuardianDisplayPatchHistory/>} />


          {/* Redirect from root to login */}
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';

const AdminPendingApproval = () => {
  const [pendingUsers, setPendingUsers] = useState([]);

  useEffect(() => {
    fetchPendingApprovals();
  }, []);

  const fetchPendingApprovals = async () => {
    try {
      const response = await fetch('/api/users/pending-approval', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Important for including cookies
      });

      if (!response.ok) throw new Error('Failed to fetch pending approvals');
      
      const data = await response.json();
      setPendingUsers(data); // Assuming the API returns an array of user objects
    } catch (error) {
      console.error('Error fetching pending approvals:', error);
    }
  };

  const handleApprove = async (userId, newRole) => {
    try {
      const response = await fetch(`/api/users/update-role/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Include any necessary headers, such as an Authorization header for JWT
        },
        credentials: 'include',
        body: JSON.stringify({ newRole }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update user role');
      }
      // Inside handleApprove, after a successful API call and before the alert
setPendingUsers(currentUsers => currentUsers.filter(user => user._id !== userId));


      alert(`User role updated successfully to ${newRole}`);
      
      // Refresh the list of pending approvals
      fetchPendingApprovals();

    } catch (error) {
      console.error('Error updating user role:', error);
      alert(error.message);
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Pending Approvals</h2>
      {pendingUsers.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className="table-dark">
              <tr>
                <th>Name</th>
                <th>Surname</th>
                <th>User ID</th>
                <th>Requested Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pendingUsers.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.surname}</td>
                  <td>{user._id}</td>
                  <td>{user.roleRequest}</td>
                  <td>
                    <button 
                      className="btn btn-success btn-sm me-2" 
                      onClick={() => handleApprove(user._id, user.roleRequest)}
                    >
                      Approve
                    </button>
                    <button className="btn btn-danger btn-sm">Reject</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="card">
          <div className="card-body text-center">
            <h3 className="card-title">No pending approvals</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPendingApproval;

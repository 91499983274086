import React, { useState, useEffect } from "react";
import GuardianGetMinorSkaters from "./GuardianGetMinorSkaters";

function GuardianDisplaySkaterProfile() {
  // const [updateTrigger, setUpdateTrigger] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [mostRecentDateUsed, setMostRecentDateUsed] =
    useState("No date used yet");
  const [mostRecentPartOfDay, setMostRecentPartOfDay] =
    useState("Not available");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //HTTP ONLYCOOKIES

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!selectedUserId) return;
  
      setIsLoading(true);
      setError(null);
  
      try {
        const response = await fetch(`/api/users/get-user-profile/${selectedUserId}`, {
          method: "GET",
          credentials: 'include', // Include cookies in the request
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch user profile");
        }
        const data = await response.json();
        setUserProfile(data);
  
        const latestPatch = data.patches
          .filter((patch) => patch.used)
          .sort((a, b) => b._id.localeCompare(a._id))[0] || {
            dateUsed: "1900-01-01",
            partOfDay: "",
        };
  
        if (latestPatch.dateUsed !== "1900-01-01") {
          setMostRecentDateUsed(latestPatch.dateUsed);
          setMostRecentPartOfDay(latestPatch.partOfDay);
        }
      } catch (error) {
        console.error("Error:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchUserProfile();
  }, [selectedUserId]);

  return (
    <div className="container mt-4 text-center">
      <h2 className="text-center mb-4">SKATER PROFILE</h2>
      <GuardianGetMinorSkaters onUserSelect={setSelectedUserId} />

      {isLoading ? (
        <p>Loading profile...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : userProfile ? (
        <div>
          <h3 className="header-name mt-5 mb-5">
            {userProfile.name + " " + userProfile.surname}
          </h3>
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Skater Name</h5>
                  <p className="card-text">{userProfile.name}</p>
                </div>
              </div>
            </div>
            {/* Surname */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Skater Surname</h5>
                  <p className="card-text">{userProfile.surname}</p>
                </div>
              </div>
            </div>
            {/* Patches Remaining */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Patches Remaining</h5>
                  <p className="card-text">{userProfile.patchesRemaining}</p>
                </div>
              </div>
            </div>
            {/* Date Purchased */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Date Purchased</h5>
                  <p className="card-text">{userProfile.datePurchased}</p>
                </div>
              </div>
            </div>
            {/* Date Used */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Date Used</h5>
                  <p className="card-text">{mostRecentDateUsed}</p>
                </div>
              </div>
            </div>
            {/* Part of Day */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Part of Day</h5>
                  <p className="card-text">{mostRecentPartOfDay}</p>
                </div>
              </div>
            </div>
            {/* Expiry Date */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Expiry Date</h5>
                  <p className="card-text">{userProfile.expiryDate}</p>
                </div>
              </div>
            </div>
            {/* Patch Card Number */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Patch Card No</h5>
                  <p className="card-text">{userProfile.patchCardNumber}</p>
                </div>
              </div>
            </div>
            {/* Invoice Purchase Number */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Invoice Purchase No</h5>
                  <p className="card-text">
                    {userProfile.purchaseInvoiceNumber}
                  </p>
                </div>
              </div>
            </div>
            {/* Contact Number */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Contact Number</h5>
                  <p className="card-text">
                    {userProfile.contactNumberSkater || "Add Contact No."}
                  </p>
                </div>
              </div>
            </div>
            {/* WP Number */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">WP Number</h5>
                  <p className="card-text">
                    {userProfile.wpnumber || "Add WP No."}
                  </p>
                </div>
              </div>
            </div>
            {/* Coach Name */}
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Coach</h5>
                  <p className="card-text">
                    {userProfile.coachName || "Add Coach"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="mt-2">Please select a skater.</p>
      )}
      <div className="d-flex justify-content-center">
        <img
          src="/western-province-figure-skating.png"
          alt="WP_Logo"
          className="d-block mx-auto"
        />
      </div>
    </div>
  );
}

export default GuardianDisplaySkaterProfile;

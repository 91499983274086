import React, { useState, useEffect } from "react";

function GuardianGetPatchHistoryUsers({ onUserSelect }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("/api/history/guardian/minor-patch-archive");
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Failed to fetch patch history users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSelectionChange = (event) => {
    onUserSelect(event.target.value);
  };

  return (
    <div className="container mt-4 mb-4 text-center">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h2 className="text-center mb-4 border-bottom pb-2">
            SKATER PATCH HISTORY
          </h2>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <select className="form-select" onChange={handleSelectionChange}>
              <option value="">Select a Skater</option>
              {users.map((user, index) => (
                <option key={index} value={user.userId}>
                  {user.name} {user.surname}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </div>
  );
}

export default GuardianGetPatchHistoryUsers;

import React, { useState, useEffect } from "react";

function AdminGetAllSkaters({ onUserSelect, selectedUserId }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          //Uses the same endpoint to GET the necessary data.
          "/api/users/skater",
          {
            method: "GET",
            credentials: "include", // Ensures cookies are included in the request
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []); // Depend on updateTrigger

  const handleSelectionChange = (event) => {
    onUserSelect(event.target.value);
  };

  return (
    <div className="container mt-4 text-center">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          {/* <h2 className="text-center mb-4 border-bottom pb-2">
           SKATER PROFILE
          </h2> */}
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <select
              className="form-select"
              value={selectedUserId}
              onChange={handleSelectionChange}
            >
              <option value="">Select a Skater</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name} {user.surname}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminGetAllSkaters;

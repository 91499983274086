import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const GuardianHomePage = () => {
  const [userName, setUserName] = useState("");

    // Initialize useNavigate
    const navigate = useNavigate();

  // NEW METHOD SERVER SIDE COOKIES

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Note: Assuming your server can identify the user from the session/JWT cookie
        // const response = await fetch(`/api/users/get-user-profile`, {
        const response = await fetch(`/api/users/current-user-profile`, {
          method: "GET",
          credentials: "include", // Important for including cookies in the request
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch user profile: ${response.status}`);
        }
        const userData = await response.json();
        setUserName(userData.name); // Assuming the response includes a 'name' field
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserProfile();
  }, []);

    // Function to handle card click
    const handleCardClick = () => {
      // Navigate to the skater profile URL
      navigate('/legal-guardian/dependent-profile'); // Update with your actual skater profile path
    };

  return (
    <div className=" admin-home-background container mt-4 text-center">
      <div className="d-flex justify-content-center">
        <img
          src="/western-province-figure-skating.png"
          alt="WP_Logo"
          className="d-block mx-auto"
        />
      </div>
      <div className="container mt-4 text-center">
        <h1 className="mb-3">SKATER PATCH</h1>
        <div className="card" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
          <div className="card-body">
            <h5 className="card-title">WELCOME {userName.toUpperCase()}</h5>
            <p className="card-text">UPDATE OR VIEW A SKATER'S PROFILE</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuardianHomePage;

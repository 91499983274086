//Basic contact page.
const SkaterContactPage = () => {
  return (
    <div className="container mt-4 text-center">
        <h2 className="text-center mb-3">Contact Us</h2>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Get in Touch</h5>
            <p className="card-text text-justify">For any queries, please contact Brenda at the ice rink on 021-535-2260. We are always here to help and look forward to hearing from you.</p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
        <img src="/western-province-figure-skating.png" alt="WP_Logo" className="d-block mx-auto" />
      </div>
    </div>
  )
}
export default SkaterContactPage;


import React, { useState, useEffect } from 'react';

function UserRegistration() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [username, setUsername] = useState('');
  const [name, setName] = useState(''); 
  const [surname, setSurname] = useState(''); 
  const [message, setMessage] = useState('');
  const [roleRequest, setRoleRequest] = useState("");

  // useEffect hook to log roleRequest whenever it changes
  useEffect(() => {
    console.log(`Role Request Updated: ${roleRequest}`);
  }, [roleRequest]); // This effect depends on roleRequest and runs whenever roleRequest changes


  const handleRegistration = async () => {
    setMessage('');

      // Check if a role has been selected
  if (!roleRequest) {
    setMessage('Please select a role before submitting.');
    return; // Exit the function early if no role is selected
  }

    const headersList = {
      "Accept": "*/*",
      "Content-Type": "application/json"
    };

    let bodyContent = JSON.stringify({ email, password, name, surname, roleRequest });

    try {
      let response = await fetch("/api/users/register", {
        method: "POST",
        body: bodyContent,
        headers: headersList
      });

      if (response.ok) {
        setMessage('Registration successful!');
        setEmail('');
        setPassword('');
        setName('');
        setSurname('');
        setRoleRequest('');
      } 
      else if (response.status === 409) {
        setMessage('User already exists. Please use a different email.');
      } 
      else {
        let error = await response.json();
        setMessage(error.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setMessage('Network error. Please try again.');
    }
    console.log(`This is the role-request data ${roleRequest}`)
  }

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title mb-4 text-center">Register</h2>
              {message && <p className={`text-${message.startsWith('Registration successful') ? 'success' : 'danger'}`}>{message}</p>}
              <form>
                <div className="mb-3">
                  <label htmlFor="registration-name" className="form-label">Name:</label>
                  <input 
                    type="text"
                    id="registration-name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="registration-surname" className="form-label">Surname:</label>
                  <input 
                    type="text"
                    id="registration-surname"
                    className="form-control"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    placeholder="Enter your surname"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="registration-email" className="form-label">Email:</label>
                  <input 
                    type="email"
                    id="registration-email"
                    className="form-control"
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Enter your email"
                    required 
                  />
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="registration-username" className="form-label">Username:</label>
                  <input 
                    type="username"
                    id="registration-username"
                    className="form-control"
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    placeholder="Enter your username"
                    required 
                  />
                </div> */}
                <div className="mb-3">
                  <label htmlFor="registration-password" className="form-label">Password:</label>
                  <input 
                    type="password"
                    id="registration-password"
                    className="form-control"
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    placeholder="Create a password"
                    required 
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">SELECT ROLE</label>
                  <div>
                    {/* <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        name="roleRequest" 
                        id="roleRequest-skater" 
                        value="skater" 
                        checked={roleRequest === "skater"} 
                        onChange={(e) => setRoleRequest(e.target.value)} 
                      />
                      <label className="form-check-label" htmlFor="roleRequest-skater">
                        Skater
                      </label>
                    </div> */}
                    <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        name="roleRequest" 
                        id="roleRequest-legalGuardian" 
                        value="legalGuardian" 
                        checked={roleRequest === "legalGuardian"} 
                        onChange={(e) => setRoleRequest(e.target.value)} 
                      />
                      <label className="form-check-label" htmlFor="roleRequest-legalGuardian">
                        Parent
                      </label>
                    </div>
                    {/* <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        name="roleRequest" 
                        id="roleRequest-coach" 
                        value="coach" 
                        checked={roleRequest === "coach"} 
                        onChange={(e) => setRoleRequest(e.target.value)} 
                      />
                      <label className="form-check-label" htmlFor="roleRequest-coach">
                        Coach
                      </label>
                    </div> */}
                    <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        name="roleRequest" 
                        id="roleRequest-admin" 
                        value="admin" 
                        checked={roleRequest === "admin"} 
                        onChange={(e) => setRoleRequest(e.target.value)} 
                      />
                      <label className="form-check-label" htmlFor="roleRequest-admin">
                        Ice Rink Staff
                      </label>
                    </div>
                  </div>
                </div>
                
                <button type="button" onClick={handleRegistration} className="btn btn-primary w-100">Register</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserRegistration;

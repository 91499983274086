import React, { useState, useEffect } from 'react';
import GuardianGetPatchHistoryUsers from './GuardianGetPatchHistoryUsers';

function GuardianDisplayPatchHistory() {
    const [selectedUserId, setSelectedUserId] = useState('');
    const [patchHistories, setPatchHistories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPatchHistories = async () => {
            if (!selectedUserId) return;

            setIsLoading(true);
            setError(null);

            try {
                const response = await fetch(`/api/history/user/${selectedUserId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch patch histories');
                }
                const data = await response.json();
                setPatchHistories(data);
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPatchHistories();
    }, [selectedUserId]);

    return (
        <div className="container mt-4 text-center">
            <GuardianGetPatchHistoryUsers onUserSelect={setSelectedUserId} />

            {isLoading ? (
                <p>Loading patch histories...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <div>
                    {/* <h3>Patch Histories</h3> */}
                    {patchHistories.length > 0 ? (
                        patchHistories.map((patch, index) => (
                            <div key={index} className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">Used Patch No.{index + 1}</h5>
                                    <p className='"card-text'>Name: {patch.name}</p>
                                    <p className='card-text'>Surname: {patch.surname}</p>
                                    <p className="card-text">Date Used: {patch.dateUsed}</p>
                                    <p className="card-text">Part of Day: {patch.partOfDay}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Please select a Skater</p>
                    )}
                </div>
            )}
                  <div className="d-flex justify-content-center">
        <img src="/western-province-figure-skating.png" alt="WP_Logo" className="d-block mx-auto" />
      </div>
        </div>
    );
}

export default GuardianDisplayPatchHistory;

import React, { useState } from "react";
// import AdminGetAllSkaters from "./AdminGetAllSkaters";
import GuardianGetMinorSkaters from "./GuardianGetMinorSkaters";
import { Card } from 'react-bootstrap';
import "../style.css";


function GuardianUpdatePatchCard() {
  const [userId, setUserId] = useState("");
  const [patches, setPatches] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [patchCardNumber, setPatchCardNumber] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [purchaseInvoiceNumber, setPurchaseInvoiceNumber] = useState("");
  const [patchesRemaining, setPatchesRemaining] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [activePatchIndex, setActivePatchIndex] = useState(null); // Track the index of the patch being edited

  const handlePurchasePatchCard = async () => {
    const newPatchCardNumber = "PC" + Math.floor(Math.random() * 10000);
    const newPurchaseInvoiceNumber = "INV" + Math.floor(Math.random() * 10000);

    try {
      const response = await fetch(`/api/users/purchase-patch-card/${userId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          patchCardNumber: newPatchCardNumber,
          purchaseInvoiceNumber: newPurchaseInvoiceNumber,
        }),
      });

      if (response.ok) {
        const updatedUserData = await response.json();

        setPatches(updatedUserData.user.patches);
        setPatchesRemaining(updatedUserData.user.patchesRemaining || 0);
        setPatchCardNumber(newPatchCardNumber);
        setPurchaseInvoiceNumber(newPurchaseInvoiceNumber);
        setSuccessMessage("New patch card purchased successfully");
        console.log(
          "Patches remaining after fetch:",
          updatedUserData.user.patchesRemaining
        );
      } else {
        throw new Error("Failed to purchase new patch card");
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage(
        "An error occurred while purchasing the new patch card."
      );
    }
  };

  const handleUserSelect = async (selectedUserId) => {

    setUserId(selectedUserId);
    if (selectedUserId) {
      try {
        const response = await fetch(
          `/api/users/get-user-profile/${selectedUserId}`,
          {
            method: "GET",
            credentials: "include", // Include cookies in the request
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const userData = await response.json();
        setPatches(userData.patches || []);
        setPatchCardNumber(userData.patchCardNumber || "");
        setPurchaseInvoiceNumber(userData.purchaseInvoiceNumber || "");
        setPatchesRemaining(userData.patchesRemaining || 0);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setPatches([]);
      setPatchCardNumber("");
      setPurchaseInvoiceNumber("");
      setPatchesRemaining(0);
    }
  };


  const handlePatchChange = (patchIndex, field, value) => {
    if (field === "used") {
      setActivePatchIndex(value ? patchIndex : null);
    }
    setPatches((prevPatches) =>
      prevPatches.map((patch, index) =>
        index === patchIndex ? { ...patch, [field]: value, updated: !value } : patch
      )
    );
  };

  const handleSubmit = async (patchId, e) => {
    e.preventDefault();

    const patchToUpdate = patches.find((patch) => patch._id === patchId);
    if (!patchToUpdate) {
      console.error("Patch not found");
      return;
    }

    const updatedPatchData = {
      used: patchToUpdate.used,
      dateUsed: patchToUpdate.dateUsed,
      partOfDay: patchToUpdate.partOfDay,
    };

    try {
      const updateResponse = await fetch(`/api/users/${userId}/patches/${patchId}`, {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedPatchData),
      });

      if (updateResponse.ok) {
        const updatedUserData = await updateResponse.json();

        const updatedPatches = patches.map((patch) =>
          patch._id === patchId ? { ...patch, updated: true } : patch
        );
        setPatches(updatedPatches);

        setPatchesRemaining(
          updatedUserData.user.patches.filter((patch) => !patch.used).length
        );

        setSuccessMessage("Patch updated successfully");
        setTimeout(() => setSuccessMessage(''), 2000);

        setActivePatchIndex(null);
      } else {
        throw new Error("Failed to update patch card");
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage("An error occurred while updating the patch card.");
      setTimeout(() => setSuccessMessage(''), 2000);
    }

    // History patches

if (patchToUpdate.used) {
  const patchHistoryData = {
    userId: userId,
    used: patchToUpdate.used,
    dateUsed: patchToUpdate.dateUsed,
    partOfDay: patchToUpdate.partOfDay,
    name: patchToUpdate.name,
    surname: patchToUpdate.surname,
    patchId: patchId, // Unique identifier for the patch
  };

  await fetch(`/api/history/${userId}/add-patch-history`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(patchHistoryData),
  });
}
  };

  return (
    <div className="container mt-4 text-center">
      <div className="row">
        <div className="col-12 mb-4">
          <h2 className="text-center mb-4">UPDATE PATCH CARD</h2>
          <GuardianGetMinorSkaters onUserSelect={(id) => handleUserSelect(id)} />
          {successMessage && (
            <div className="alert alert-success m-4">{successMessage}</div>
          )}
        </div>

        {userId && (
          <>
            {patchesRemaining === 0 || patches.length === 0 ? (
              <div className="d-flex justify-content-center">
                <button onClick={handlePurchasePatchCard} className="btn btn-dark m-3">
                  Purchase New Patch Card
                </button>
              </div>
            ) : (
              <Card className="text-center mt-3 mb-3">
                <Card.Header>Patch Card Details</Card.Header>
                <Card.Body>
                  <Card.Title>Patch Card Number: {patchCardNumber}</Card.Title>
                  <Card.Text>Patches Remaining: {patchesRemaining}</Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted">Purchase Invoice: {purchaseInvoiceNumber}</Card.Footer>
              </Card>
            )}

            {patches.map((patch, index) => {
              if (!patch.updated) {
                const isEditingDisabled = activePatchIndex !== null && activePatchIndex !== index;

                return (
                  <div key={index} className="row mb-2">
                    <div className="col-md-5 mb-1 mb-md-0">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={patch.used}
                          disabled={isEditingDisabled}
                          onChange={(e) => handlePatchChange(index, "used", e.target.checked)}
                          id={`patch-used-${index}`}
                        />
                        <label className="form-check-label" htmlFor={`patch-used-${index}`}>
                          Patch #{index + 1} {patch.used ? "Used" : "Available"}
                        </label>
                      </div>
                    </div>
                    {index === activePatchIndex && (
                      <>
                        <div className="col-md-3 mb-2 mb-md-0">
                          <input
                            type="date"
                            className="form-control"
                            value={patch.dateUsed || ""}
                            onChange={(e) => handlePatchChange(index, "dateUsed", e.target.value)}
                            disabled={isEditingDisabled}
                          />
                        </div>
                        <div className="col-md-3">
                          <select
                            className="form-control"
                            value={patch.partOfDay || ""}
                            onChange={(e) => handlePatchChange(index, "partOfDay", e.target.value)}
                            disabled={isEditingDisabled}
                          >
                            <option value="">Select Session</option>
                            <option value="Morning">Morning</option>
                            <option value="Afternoon">Afternoon</option>
                            <option value="Evening">Evening</option>
                          </select>
                        </div>
                        <div className="col-md-1">
                          <button
                            onClick={(e) => handleSubmit(patch._id, e)}
                            className="btn btn-primary"
                            disabled={isEditingDisabled}
                          >
                            Update
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </>
        )}
      </div>
      <div className="d-flex justify-content-center">
        <img src="/western-province-figure-skating.png" alt="WP_Logo" className="d-block mx-auto" />
      </div>
    </div>
  );
}
  

export default GuardianUpdatePatchCard;
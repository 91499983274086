import React, { useState, useEffect } from "react";

function GuardianUpdateGuardian() {
  const [userId, setUserId] = useState("");
  const [name, setUserName] = useState("");
  const [surname, setUserSurname] = useState("");
  const [email, setUserEmail] = useState("");
  const [password, setUserPassword] = useState("");
  const [contactNumberAdmin, setContactNumberAdmin] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchAdminProfile = async () => {
      try {
        const response = await fetch(`/api/users/current-user-profile`, {
          method: "GET",
          credentials: "include", // Include cookies in the request
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch admin details");
        }
        const adminData = await response.json();

        setUserId(adminData._id); //Mechanism for attaching userId from cookie submitted by server in controller.
        setUserName(adminData.name || "");
        setUserSurname(adminData.surname || "");
        setUserEmail(adminData.email || "");
        setUserPassword(adminData.password || "");
        setContactNumberAdmin(adminData.contactNumberAdmin || "");
        //Verify admin data success
        // console.log(adminData)
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAdminProfile();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyContent = JSON.stringify({
      name,
      surname,
      email,
      password,
      contactNumberAdmin,
    });

    let messageTimeout;

    try {
      const response = await fetch(`/api/users/update-user-profile/${userId}`, {
        method: "PUT",
        credentials: "include", // Include cookies in the request
        headers: { "Content-Type": "application/json" },

        body: bodyContent,
      });

      if (response.ok) {
        setSuccessMessage("Profile Updated Successfully");
        // Clear the message after 1.5 seconds
        messageTimeout = setTimeout(() => {
          setSuccessMessage("");
        }, 1500);
      } else {
        console.error("Failed to update profile");
        setSuccessMessage("Failed to update profile");
        // Clear the message after 1.5 seconds
        messageTimeout = setTimeout(() => {
          setSuccessMessage("");
        }, 1500);
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage("An error occurred while updating the profile.");
      // Clear the message after 1.5 seconds
      messageTimeout = setTimeout(() => {
        setSuccessMessage("");
      }, 1500);
    }

    // Cleanup timeout on component unmount
    return () => {
      clearTimeout(messageTimeout);
    };
  };

  return (
    <div className="container mt-4 text-center">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center mb-4 font-weight-bold">
            UPDATE GUARDIAN PROFILE
          </h2>
          {successMessage && (
            <div className="alert alert-success">{successMessage}</div>
          )}
        </div>

        {userId && (
          <form onSubmit={handleSubmit} className="row g-3">
            <div className="col-md-6">
              <label htmlFor="name" className="form-label">
               Name:
              </label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Guardian Name"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="Surname" className="form-label">
                Surname:
              </label>
              <input
                type="text"
                className="form-control"
                value={surname}
                onChange={(e) => setUserSurname(e.target.value)}
                placeholder="Guardian Surname"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="Email" className="form-label">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Guardian Email"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="Password" className="form-label">
                Password:
              </label>
              <input
                type="text"
                className="form-control"
                value={password}
                onChange={(e) => setUserPassword(e.target.value)}
                placeholder="Guardian Password"
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="admin-contact-number" className="form-label">
              Contact:
              </label>
              <input
                type="tel"
                className="form-control"
                value={contactNumberAdmin}
                onChange={(e) => setContactNumberAdmin(e.target.value)}
                placeholder="Guardian Contact No."
              />
            </div>

            <div className="row g-2 mt-3 justify-content-center">
              {" "}
              {/* Added justify-content-center */}
              <div className="col-12 col-md-auto">
                <button type="submit" className="btn btn-primary w-100">
                  Update Guardian
                </button>
              </div>
              {/* <div className="col-12 col-md-auto">
                <AdminDeleteUserPage userId={userId} />
              </div> */}
            </div>
          </form>
        )}
      </div>
      <div className="d-flex justify-content-center">
        <img
          src="/western-province-figure-skating.png"
          alt="WP_Logo"
          className="d-block mx-auto"
        />
      </div>
    </div>
  );
}

export default GuardianUpdateGuardian;

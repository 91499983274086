import React, { useState, useEffect } from "react";

function SkaterProfileUpdate() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [legalGuardian, setLegalGuardian] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchSkaterProfile();
  }, []);

  const fetchSkaterProfile = async () => {
    try {
      const response = await fetch(`/api/users/current-user-profile`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch skater details");
      }

      const skaterData = await response.json();

      setName(skaterData.name || "");
      setSurname(skaterData.surname || "");
      setEmail(skaterData.email || "");
      setLegalGuardian(skaterData.legalGuardian || "");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyContent = JSON.stringify({
      name,
      surname,
      email,
      legalGuardian
      // It's best practice not to include the password field in general updates
      // Consider creating a separate password change flow
    });

    try {
      const response = await fetch(`/api/users/update-skater-profile`, {
        method: "PUT",
        credentials: "include", // Important for including cookies in the request
        headers: { "Content-Type": "application/json" },
        body: bodyContent,
      });

      if (response.ok) {
        setSuccessMessage("Profile Updated Successfully");
        fetchSkaterProfile(); // Optionally refresh user data
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error || "Failed to update profile");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while updating the profile.");
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-center">
        <h2>UPDATE SKATER PROFILE</h2>
      </div>
      <form onSubmit={handleSubmit} className="row g-3">
        <div className="col-md-6">
          <label htmlFor="name" className="form-label">
            Name:
          </label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="surname" className="form-label">
            Surname:
          </label>
          <input
            type="text"
            className="form-control"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            placeholder="Surname"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="email" className="form-label">
            Email:
          </label>
          <input
            type="text"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="legalGuardian" className="form-label">
            Legal Guardian:
          </label>
          <input
            type="text"
            className="form-control"
            value={legalGuardian}
            onChange={(e) => setLegalGuardian(e.target.value)}
            placeholder="Legal Guardian"
          />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Update Profile
          </button>
        </div>
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
      </form>
      <div className="d-flex justify-content-center">
        <img
          src="/western-province-figure-skating.png"
          alt="WP_Logo"
          className="d-block mx-auto"
        />
      </div>
    </div>
  );
}

export default SkaterProfileUpdate;
